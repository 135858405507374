var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('van-pull-refresh',{staticStyle:{"min-height":"100vh"},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished},on:{"load":_vm.getData},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item,index){return _c('van-cell-group',{key:index,style:({
                            'border-left': '4px solid',
                            'border-radius': '6px',
                            'border-image' : `${_vm.resultMap[item.type === '1' ? '7' : '4'].bgColor}`
                        }),attrs:{"url":'/#/sendInfo?id=' +
						item.processDefinitionId +
						'&saveId=' +
						item.saveId +
						'&page=myRequest' +
						_vm.isHasNav},on:{"click":function($event){return _vm.$router.push({
							path:
							'/sendInfo?id=' +
							item.processDefinitionId +
							'&saveId=' +
							item.saveId +
							'&page=myRequest' +
							_vm.isHasNav
								})}}},[_c('van-cell',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"titleAll",staticStyle:{"font-weight":"600","font-size":"15px"}},[_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.name))])]),_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.delBtn(item.saveId)}}},[_c('van-icon',{attrs:{"name":"delete-o","size":"22px"}})],1)])]},proxy:true}],null,true)}),_c('div',{staticClass:"nodecEll"},[_c('span',{staticClass:"cellTitle"},[_vm._v(" 发起人:"+_vm._s(item.username)+" ")]),_c('span',{staticClass:"cellTitle"},[_vm._v(" 来源类型:"),(item.type === '1')?_c('span',{staticClass:"titleColor"},[_vm._v("保存")]):_vm._e(),(item.type === '2')?_c('span',[_vm._v("撤回")]):_vm._e()]),_c('span',{staticClass:"cellTitle"},[_vm._v(" 提交时间:"+_vm._s(_vm.formatTime(item.createTime))+" ")])])],1)}):_vm._e(),(_vm.list.length == 0 && _vm.finished)?_c('div',{staticClass:"empty"},[_c('van-empty',{attrs:{"description":"没有内容，空空如也~","image":require('@/assets/img/mag_pic_noinformation.svg')}})],1):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }