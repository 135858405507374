/** 流程状态 下拉 map */
export const resultList = [
    { label: "处理中", value: 1 },
    { label: "通过", value: 2 },
    { label: "退回", value: 3 },
    { label: "已取消", value: 4 },
    { label: "回退", value: 5 },
    { label: "撤回", value: 6 },
    { label: "保存", value: 7 },
];

/** 流程状态 枚举 */
export const resultMap = {
    1: {
        text: "处理中",
        color: "#4988FF",
        bgColor:"linear-gradient(#99BDFA ,#2757DA)5 5",
        img: require("@/assets/img/rs-ing.svg"),
    },
    2: {
        text: "已通过",
        color: "#1AB370",
        bgColor:"linear-gradient(#CAEFED ,#6AC695)5 5",
        img: require("@/assets/img/rs-pass.svg"),
    },
    3: {
        text: "退回",
        color: "#E60505",
        bgColor:"linear-gradient(#F5F5F5 ,#E70E0E)5 5",
        img: require("@/assets/img/rs-reject.svg"),
    },
    4: {
        text: "已取消",
        color: "#555555",
        bgColor:"linear-gradient(#F8F8F8 ,#AAA)5 5",
        img: require("@/assets/img/rs-cancel.svg"),
    },
    5: {
        text: "回退",
        color: "#E60505",
        bgColor:"linear-gradient(#99BDFA ,#2757DA)5 5",
        img: require("@/assets/img/rs-cancel.svg"),
    },
    6: {
        text: "撤回",
        color: "#555555",
        bgColor:"linear-gradient(#99BDFA ,#2757DA)5 5",
        img: require("@/assets/img/rs-cancel.svg"),
    },
    7: {
        text: "保存",
        color: "#F5A911",
        bgColor:"linear-gradient(#FFE2C2,#F5A911)5 5",
        img: require("@/assets/img/rs-cancel.svg"),
    },
};
