<template>
	<div class="wrap">
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh;">
			<van-list v-model="loading" :finished="finished" @load="getData">
				<template v-if="list.length > 0">
					<van-cell-group v-for="(item, index) in list" :key="index"
					:style="{
                            'border-left': '4px solid',
                            'border-radius': '6px',
                            'border-image' : `${resultMap[item.type === '1' ? '7' : '4'].bgColor}`
                        }"
					@click="
						$router.push({
							path:
							'/sendInfo?id=' +
							item.processDefinitionId +
							'&saveId=' +
							item.saveId +
							'&page=myRequest' +
							isHasNav
								})
					"
					:url="
						'/#/sendInfo?id=' +
						item.processDefinitionId +
						'&saveId=' +
						item.saveId +
						'&page=myRequest' +
						isHasNav
					"
					>
						<van-cell>
							<template #title>
								<div class="titleAll" style="font-weight: 600;font-size: 15px;">
									<div class="title">
										<!-- <div class="line"></div> -->
										<div>{{ item.name }}</div>
									</div>
									<div @click.stop='delBtn(item.saveId)'>
										<van-icon name="delete-o" size='22px' />
									</div>
								</div>
							</template>
						</van-cell>

						<div class="nodecEll">
                            <span class="cellTitle">
                                发起人:{{item.username}}
                            </span>
							<span class="cellTitle">
                                来源类型:<span v-if="item.type === '1'" class="titleColor">保存</span><span v-if="item.type === '2'">撤回</span>
                            </span>
                            <span class="cellTitle">
                                提交时间:{{formatTime(item.createTime)}}
                            </span>
                        </div>

						<!-- <van-cell :title="'来源类型'" :value="item.type === '1' ? '保存' : '撤回'"></van-cell>

						<van-cell :title="'提交时间：'" :value="formatTime(item.createTime)"></van-cell> -->

						<!-- <van-cell :title="'查看详情'" is-link :url="
								'/#/sendInfo?id=' +
								item.processDefinitionId +
								'&saveId=' +
								item.saveId +
								'&page=myRequest' +
								isHasNav
							" :clickable="true"></van-cell> -->
					</van-cell-group>
				</template>

				<div class="empty" v-if="list.length == 0 && finished">
					<van-empty description="没有内容，空空如也~"
						:image="require('@/assets/img/mag_pic_noinformation.svg')"></van-empty>
				</div>
			</van-list>
		</van-pull-refresh>
	</div>
</template>

<script>
import { resultMap } from "@/constants/map";
	import {
		formatTime
	} from "@/utils/DateUtil";
	import {
		getMyReqFlowList,
		deleteFlowPath
	} from "@/api/flow";
	import {
		Dialog
	} from 'vant';
	import {
		Toast
	} from "vant";

	export default {
		data() {
			return {
				loading: false,
				finished: false,
				refreshing: false,
				page: 1,
				size: 10,
				list: [],
			};
		},
		components: {},
		watch: {},
		created() {},
		methods: {
			//删除按钮
			delBtn(id) {
				try {
					Dialog.confirm({
							title: '提示',
							message: '确认删除该流程吗？',
						})
						.then(() => {
							deleteFlowPath({
								saveId: id
							}).then((res) => {
								if (res.status == 200) {
									Toast({
										message: '删除成功',
									});
									this.page = 1;
									this.list = [];
									this.getData();
								}
							});
						})
						.catch(() => {
							// on cancel
						});
				} catch (e) {
					//TODO handle the exception
				}

			},
			getData() {
				try {
					let params = {
						pageNo: this.page,
						pageSize: this.size,
					};
					getMyReqFlowList(params).then((res) => {
						if (res.status == 200) {
							if (this.refreshing) {
								this.list = [];
								this.refreshing = false;
							}
							if (res.data && res.data.list.length > 0) {
								this.list = this.list.concat(res.data.list);
								this.page += 1;
							}
							this.loading = false;
							if (this.list.length >= res.data.total) {
								this.finished = true;
							}
						}
					});
				} catch (e) {
					this.loading = false;
					this.finished = true;
				}
			},
			onRefresh() {
				// 清空列表数据
				this.finished = false;
				this.loading = true;
				this.page = 1;
				// 重新加载数据
				this.getData();
			},
			formatTime,
		},
		computed: {
			// 从哪个页面跳转过来的
			isHasNav() {
				return this.$route.query.from == "jntPc" ? "&hasNav=1" : "";
			},
			resultMap() {
                return resultMap;
            },
		},
	};
</script>

<style lang="less" scoped>
 	/deep/.van-cell{
        display:flex;
        align-items:center;
        padding:8px;
    }
    /deep/.van-cell::after{
        right: 8px;
        left: 8px
    }
	.wrap {
		padding: 0 10px;
		.nodecEll{
			font-size:12px;
            // display:flex;
			// width:100%;
			padding: 8px;
			overflow:hidden;
				text-overflow:ellipsis;
				white-space:nowrap;
            .cellTitle{
				.titleColor{
					color:#F5A911
				}
				padding-right: 8px;
				
        //         font-size: 12px;
                }
           
        }

		.titleAll {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				display: flex;
				align-items: center;
				margin-right: 20px;

				.line {
					width: 0.2rem;
					height: 0.8rem;
					background: #037ae7;
					margin-right: 10px;
				}
			}
		}



		/deep/ .blue .van-cell__value {
			color: #037ae7;
		}

		/deep/ .red .van-cell__value {
			color: rgb(255, 77, 79);
		}

		/deep/ .van-cell-group {
			margin: 12px 0;
		}
	}
</style>